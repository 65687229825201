import { Injectable } from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class DataService {
  showErrorInUI = false;
  constructor() { }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }
  clearLocalStorage(): void {
    localStorage.clear();
  }
  deleteLocalSessionItem(field: string) {
    localStorage.removeItem(field)
  }

  setSessionStorageItem(field: string, value: any): void {
    if (typeof value !== 'string') {
      value = value.toString()
    }
    sessionStorage.setItem(field, value)
  }
  setLocalStorageItem(field: string, value: any): void {
    if (typeof value !== 'string') {
      value = value.toString()
    }
    localStorage.setItem(field, value)
  }

  getSessionStorageItem(field: string): string | object | any[] {
    const fieldData = sessionStorage.getItem(field) || '';
    try {
      const parsedData = JSON.parse(fieldData);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else if (typeof parsedData === 'object') {
        return parsedData;
      } else if (typeof parsedData === 'string') {
        return parsedData;
      }
      return fieldData;
    } catch (error) {
      return fieldData;
    }
  }

  getLocalStorageItem(field: string): any {
    const fieldData = localStorage.getItem(field) || '';
    try {
      const parsedData = JSON.parse(fieldData);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else if (typeof parsedData === 'object') {
        return parsedData;
      } else if (typeof parsedData === 'string') {
        return parsedData;
      }
      return fieldData;
    } catch (error) {
      return fieldData;
    }
  }
  saveLoginResponse(loginResponse: any, emailId?: any) {
    if (typeof(loginResponse)=='object' && loginResponse) {
      for(let key in loginResponse){
        localStorage.setItem(key, loginResponse[key]);
      }
      if (emailId) {
        localStorage.setItem('emailId', emailId);
      }
    }

  }

}
