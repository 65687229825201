import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable({
  providedIn: 'root',
})
export class CustomMessageService {
  notificationChange: Subject<object> = new Subject<object>();

  /**
   * Notification Message Service
   * @param severity - accepts severity types `success` `info` `warn` `error`.
   * @param summary - heading for the notification
   * @param detail - details of the notification
   * @param customSuccess - for custom success message for get in touch forms
   */
  notify(
    severity: Severities,
    summary: string,
    detail: string,
    customSuccess?: boolean
  ): void {
    if (detail) {
      if (severity === 'error') {
        summary = 'ERROR';
      }
      this.notificationChange.next({
        severity,
        summary,
        detail,
        life: 5000,
        customSuccess: customSuccess ? true : false,
      });
    }
  }
}
